import queryString from "query-string";
import ApiService from "./ApiService";

// Users
export async function apiGetUserList(data) {
  return ApiService.fetchData({
    url: `User/List?${queryString.stringify(data, { skipNull: true })}`,
    method: "get",
  });
}
export async function apiGetUser(data) {
  return ApiService.fetchData({
    url: `User/Get?id=${data}`,
    method: "get",
  });
}
export async function apiGetUserWalletHistory(data) {
  return ApiService.fetchData({
    url: `User/GetWalletHistory?${queryString.stringify(data, {
      skipNull: true,
    })}`,
    method: "get",
  });
}
export async function apiPostUser(data) {
  return ApiService.fetchData({
    url: `User/Upsert`,
    method: "post",
    data,
  });
}

export async function apiChangeUserLanguage(data) {
  return ApiService.fetchData({
    url: `User/ChangeLanguage`,
    method: "post",
    data,
  });
}

export async function apiDeleteUser(data) {
  return ApiService.fetchData({
    url: `User/Delete?id=${data}`,
    method: "delete",
  });
}

//Profile
export async function apiUpdateMyProfile(data) {
  return ApiService.fetchData({
    url: `User/UpdateProfile`,
    method: "post",
    data,
  });
}

export async function apiLoginWithUser(data) {
  return ApiService.fetchData({
    url: `User/LoginWithUser?id=${data}`,
    method: "get",
  });
}

export async function apiGetMyProfile() {
  return ApiService.fetchData({
    url: `User/WhoAmI`,
    method: "get",
  });
}

//Roles
export async function apiGetRoleList(data) {
  return ApiService.fetchData({
    url: `Role/List?${queryString.stringify(data, { skipNull: true })}`,
    method: "get",
  });
}

export async function apiGetRole(data) {
  return ApiService.fetchData({
    url: `Role/Get?id=${data}`,
    method: "get",
  });
}

export async function apiPostRole(data) {
  return ApiService.fetchData({
    url: `Role/Upsert`,
    method: "post",
    data,
  });
}

export async function apiDeleteRole(data) {
  return ApiService.fetchData({
    url: `Role/Delete?id=${data}`,
    method: "delete",
  });
}

// Wallet

export async function apiGetMyWallet() {
  return ApiService.fetchData({
    url: `User/MyWallet`,
    method: "get",
  });
}

export async function apiGetMyWalletHistory(data) {
  return ApiService.fetchData({
    url: `User/MyWalletHistory?${queryString.stringify(data, {
      skipNull: true,
    })}`,
    method: "get",
  });
}

export async function apiGetUserWallet(data) {
  return ApiService.fetchData({
    url: `User/GetWallet?userId=${data}`,
    method: "get",
  });
}

export async function apiRedeem(data) {
  return ApiService.fetchData({
    url: `User/Redeem?${queryString.stringify(data, { skipNull: true })}`,
    method: "post",
  });
}
