import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentRouteKey: "",
  currentReport: "",
  onboard: localStorage.getItem("onboard") || false,
  varta: localStorage.getItem("varta")
    ? JSON.parse(localStorage.getItem("varta"))
    : {
        active: false,
        showwarning: false,
      },
};

export const commonSlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setCurrentReport: (state, action) => {
      state.currentReport = action.payload;
    },
    setOnboard: (state, action) => {
      state.onboard = action.payload;
    },
    setVarta: (state, action) => {
      state.varta = action.payload;
      localStorage.setItem("varta", JSON.stringify(action.payload));
    },
  },
});

export const { setCurrentRouteKey, setCurrentReport, setOnboard, setVarta } =
  commonSlice.actions;

export default commonSlice.reducer;
